import { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { BsCartCheckFill } from 'react-icons/bs';
import './ThankYou.css';

const ThankYou = () => {

  useEffect(() => {
    ReactPixel.track('Purchase', {
      value: 14,
      currency: 'USD',
      content_ids: ['jaysuing-id-2'],
      contents: [{
        id: 'jaysuing-id-2',
        quantity: 1,
        price: 14
      }],
    });
  }, []);

  return (
    <div className="parentContainer">
      <section className='thankYou'>
        <BsCartCheckFill />
        <h1>شكرا جزيلاً على ثقتكم سيتم الإتصال بكم من فريق الدعم في ساعات القادمة لتأكيد طلبكم</h1>
        {/* <table>
      <thead>
        <tr>
          <th>المجموع</th>
          <th>الكمية</th>
          <th>تمن القطعة</th>
          <th>منتج</th>
        </tr>
      </thead>
      <tbody>
          <tr>
            <td>299 ريال</td>
            <td>3</td>
            <td> ريال299 ريال</td>
            <td>الأصلي JAYSUING بخاخ</td>
          </tr>
      </tbody>
    </table> */}
      <div className='invoice'>
        <ul>
          <li>JAYSUING</li>
          <li>٣ عبوات</li>
          <li>مجاني</li>
          {/* <li>٤٤٩ ريال</li> */}
        </ul>
        <ul>
          <li>المنتج</li>
          <li>الكمية</li>
          <li>الشحن</li>
          {/* <li>المجموع</li> */}
        </ul>
      </div>
      </section>
    </div>
  )
}

export default ThankYou;