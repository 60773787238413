import { useEffect, useState } from "react";
import { BsStarFill } from 'react-icons/bs';
import commentI from '../../assets/GingerTemp2_KSA/comments/comment_1.png';
import commentII from '../../assets/GingerTemp2_KSA/comments/comment_2.png';
import './Comment.css';

const Comment = () => {
  const [comments, setComments] = useState([]);

useEffect(() => {
  fetch(`https://us-central1-involvement-api.cloudfunctions.net/capstoneApi/apps/${process.env.REACT_APP_Comment_API}/comments?item_id=item1`)
    .then(response => response.json())
    .then(data => {setComments(data)})
    .catch(error => console.error(error));
}, []);

return (
  <div className="slider">
    <h2>أكثر من 1500 زبون راضي عن منتجنا</h2>
    <div className="slider-container">
      {comments.map((comment , i) => (
        <div className="slider-item" key={i}>
          <h3 className="commentAuthor"><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /><BsStarFill /> {comment.username}</h3>
          <p>{comment.comment}</p>
          { i === 1 ? <img src={commentI} alt="/" />: ' '}
          { i === 4 ? <img src={commentII} alt="/" />: ' '}
        </div>
      ))}
    </div>
  </div>
);

}

export default Comment;