// import './App.css';
import { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import Comment from './components/Comments/Comment';
import ThankYou from './components/Thanks/ThankYou';
import GingerTemp2KSA from './templates/GingerTemp2_KSA';
import GingerTemp2QAT from './templates/GingerTemp2_QAT';
import GingerTemp2KWT from './templates/GingerTemp2_KWT';
import GingerTemp2UAE from './templates/GingerTemp2_UAE';
import MassagerKSA from './templates/Massager_KSA';

function App() {

  useEffect(() => {
    ReactPixel.pageView();
  }, []);

  return (
    <>
    <Routes>
      <Route path="/Landing_page" element={<Navigate to="/Ginger012292UAE" />} />
      <Route path="/" element={<Navigate to="/Ginger012292UAE" />} />
      <Route
        path='/Ginger012292KSA'
        element={(
          <>
            <GingerTemp2KSA />
            <Comment />
          </>
      )} />
      <Route
        path='/Ginger012292QAT'
        element={(
          <>
            <GingerTemp2QAT />
            <Comment />
          </>
      )} />
      <Route
        path='/Ginger012292KWT'
        element={(
          <>
            <GingerTemp2KWT />
            <Comment />
          </>
      )} />
      <Route
        path='/Ginger012292UAE'
        element={(
          <>
            <GingerTemp2UAE />
            <Comment />
          </>
      )} />
      <Route
        path='/Massager012292KSA'
        element={(
          <>
            <MassagerKSA />
            <Comment />
          </>
      )} />
      <Route path='/Ginger012292KSA/thank-you' element={ <ThankYou /> } />
    </Routes>
    </>
  );
}

export default App;
