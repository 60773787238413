import { Helmet } from 'react-helmet';
import emailjs from 'emailjs-com';
import '../style/GingerTemp2_KSA.css';
import btnWhite from "../assets/GingerTemp2_KSA/Btn_White.png";
import btnGreen from "../assets/GingerTemp2_KSA/Btn_Green.png";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MassagerKSA = () => {
  const pics = [
    {
      id: 1,
      img: require("../assets/Massager_KSA/Page_1.png"),
      back: 'green',
    },
    {
      id: 2,
      img: require("../assets/Massager_KSA/Page_2.png"),
      back: 'white',
    },
    {
      id: 3,
      img: require("../assets/Massager_KSA/Page_3.png"),
      back: 'green',
    },
    {
      id: 4,
      img: require("../assets/Massager_KSA/Page_4.png"),
      back: 'white',
    },
    {
      id: 5,
      img: require("../assets/Massager_KSA/Page_5.png"),
      back: 'white',
    },
  ]
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    product: 'Massager_KSA',
    name: '',
    phone: '',
    adresse: '',
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, formData, process.env.REACT_APP_PUBLIC_KEY)
      .then(() => {
        setFormData({
          name: '',
          phone: '',
          adresse: '',
        });
      }, (error) => {
        console.log(error.text);
      });

    // Redirect to thank you page
    navigate('/Massager012292KSA/thank-you');
  };

  return (
    <>
    <Helmet>
      <meta
        name="description"
        content="مدلــك كـهـربـــائي مــتــعــدد الوظــائف"
      />
      <meta name="og:title" content='وسادة التدليك الكهربائية' />
      <meta name="og:description" content='وسادة التدليك الكهربائية ثلاثية الأبعاد لتخفيف الام الرقبة وأعلى الظهر.' />
      <meta name="og:image" content={pics[0].img} />
      <title>وسادة التدليك الكهربائية</title>
    </Helmet>
      <section className='body'>
        {
          pics.map((pic) => (
            <div key={pic.id}>
              <img className='imgBack' src={pic.img} alt={pic.id} />
              <a href='#order' className='btn'><img src={pic.back === 'white' ? btnGreen : btnWhite} alt={"button"+ pic.back} /></a>
            </div>
          ))
        }
      </section>
      <section className='order' id='order'>
        <div className='info'>
          <h2 className="formTitle">التوصيل مجاني و الدفع عند الاستلام</h2>
          <p>لشراء المرجو إدخال معلوماتك ليتصل بك فريق الدعم الفني</p>
        </div>
        <form className="form" onSubmit={handleSubmit}>
          <div className="inputDiv">
            <input className="inputForm name" type="text" name="name" value={formData.name} onChange={handleChange} required />
            <label>الإسم الكامل / Name</label>
          </div>
          <div className="inputDiv">
            <input className="inputForm score" type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
            <label>رقم الهاتف / Phone</label>
          </div>
          <div className="inputDiv">
            <input className="inputForm adresse" type="text" name="adresse" value={formData.adresse} onChange={handleChange} />
            <label>العنوان / Address</label>
          </div>
          <input className="submit" type="submit" value="اطلب الان ب449 ريال فقط" />
        </form>
      </section>
    </>
  )
}

export default MassagerKSA;